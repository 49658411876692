<div id="wrapper">
    <rsb-sidebar *ngIf="!hideMainSidebar && !hideSidebar && !hidePropertyBar"></rsb-sidebar>
    <app-company-sidebar *ngIf="hideMainSidebar"></app-company-sidebar>
    <app-project-sidebar *ngIf="hideSidebar"></app-project-sidebar>
    <app-propertysidebar *ngIf="hidePropertyBar"></app-propertysidebar>
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <rsb-header></rsb-header>
            <router-outlet></router-outlet>
        </div>
        <rsb-footer></rsb-footer>
    </div>
    <rsb-spinner></rsb-spinner>
</div>