import { Component, OnInit, ElementRef, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { MapsAPILoader } from '@agm/core';
import { RsbService } from 'src/app/service/rsb.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.css']
})
export class MyProjectsComponent implements OnInit {
  allListings:Array<any> = []
  constructor(
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private route:Router
  ) { }

  ngOnInit(): void {
    this.getAllListings();
  }
  addRouteUnit(listings){
    this.lookupModel.setVendorProperty(listings);
    this.route.navigate(['unit'])
  }

  getAllListings(): void {
    this.rsbService.getAllProjectListings(1).subscribe((rsp) => {
      if(rsp.statusCode === 0 && rsp.contents.length) {
        this.allListings = rsp.contents
      } else {
        this.allListings = []
      }
    })
  }
}
