import { Component, OnInit } from '@angular/core';
import { RsbService } from 'src/app/service/rsb.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'rsb-post-publish',
  templateUrl: './post-publish.component.html',
  styleUrls: ['./post-publish.component.css']
})
export class PostPublishComponent implements OnInit {

  constructor(
    private rsbService: RsbService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  publish(): void {
    this.rsbService.publishPlan().subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        this.toastrService.success('Property has been published successfully.');
        this.router.navigate(['myProperties']);
      }
    });
  }
}
