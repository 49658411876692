<div class="card padding-card t-align-l no-radius border">
    <div class="card-body">
        <h5 class="card-title mb-4">Project Floor Plans</h5>
        <div>
            <button (click)="addSwatch()" class="btn btn-primary btn-lg">Add New</button>
        </div>
        <div class="row" *ngFor="let swatch of swatchList; let i = index;">
            <div class="form-group col-md-4">
                <label for="location">Enter Amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Enter Amount" id="location"
                        [(ngModel)]="swatchList[i].amount">
                </div>
            </div>
            <div class="form-group col-md-4">

                <label for="location">Enter BHK</label>
                <div class="input-group">

                    <select [(ngModel)]="swatchList[i].bhk" class="form-control">
                        <option value="">select</option>
                        <option *ngFor="let prop of availableAttributes" value="{{prop}}">{{prop}}</option>
                    </select>
                </div>
            </div>

            <div class="form-group col-md-12">
                <label for="Company Person">Floor Image</label>
                <div class="fuzone" *ngIf="!swatchList[i].image">
                    <img class="card-img-top" style="height: 150px;" *ngIf="propertyImage" [src]="propertyImage"
                        alt="Card image cap">
                    <div class="fu-text" *ngIf="!propertyImage">
                        <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                            upload</span>
                    </div>
                    <input class="upload" type="file" #imageInput accept="image/*"
                        (change)="uploadAws($event, imgIndx, i)">
                </div>
                <div *ngIf="swatchList[i].image">
                    <img src="{{swatchList[i].image}}" />
                </div>
            </div>
        </div>
    </div>

</div>
<button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>
<button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>

