import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-vendor-dashboard',
  templateUrl: './vendor-dashboard.component.html',
  styleUrls: ['./vendor-dashboard.component.css']
})
export class VendorDashboardComponent implements OnInit {
  getMylead:any;

  constructor(
    private rsbService:RsbService,
    private rsbLookup:RsbLookupModel,
    private route:Router) { }
  Postproperty(route:any){
    this.route.navigate([route]);
  }
  Applyloan(route:any){
    this.route.navigate([route]);
  }

  ngOnInit(): void {
    console.log("my lead")
    console.log(JSON.stringify(this.getMylead))
    console.log(this.rsbLookup.getLoggedUser())
    this.getMyleads()
  }
  getMyleads() {
    this.rsbService.myLeads().subscribe((rsp) => {
      this.getMylead = rsp.contents;


    })

  }

}
