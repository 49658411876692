import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostPropertyComponent } from './components/vendor/post-property/post-property.component';
import { ForgotpasswordComponent } from './shared/components/forgotpassword/forgotpassword.component';
import { VendorDashboardComponent } from './components/vendor/vendor-dashboard/vendor-dashboard.component';
import { LoanComponent } from './components/vendor/loan/loan.component';
import { AuthGuard } from './common/guards/auth-guard';
import { MyPropertiesComponent } from './components/vendor/my-properties/my-properties.component';
import { MyProjectsComponent } from './components/vendor/my-projects/my-projects.component';
import { AddCompanyComponent } from './components/companies/add-company/add-company.component';
import { CompanySidebarComponent } from './components/companies/company-sidebar/company-sidebar.component';
import { CompanyinfoComponent } from './components/companies/companyinfo/companyinfo.component';
import { CompanyProfileComponent } from './components/companies/company-profile/company-profile.component';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { MyLeadsComponent } from './components/vendor/my-leads/my-leads.component';
import { MyviewLeadsComponent } from './components/myview-leads/myview-leads.component';
import { MyCompanyComponent } from './components/my-company/my-company.component';
import { UnitsComponent } from './components/projects/units/units.component';



const routes: Routes = [
 
  
  {
    path: 'post-property/:propertyName',
    canActivate:[AuthGuard],
    component: PostPropertyComponent
  }, 
  {
    path: '',
    component: VendorDashboardComponent
  },
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    component: VendorDashboardComponent
  },
  {
    path:'myProperties',
    canActivate:[AuthGuard],
    component:MyPropertiesComponent
  },
  {
    path:'myProjects',
    component:MyProjectsComponent
  },
  {
    path:'unit',
    component:UnitsComponent
  },
  {
    path:'addCompany/:companyName',
    canActivate:[AuthGuard],
    component: AddCompanyComponent
  },
  {
    path:'addProject/:propertyId',
    canActivate:[AuthGuard],
    component: AddProjectComponent
  },
  {
    path:'companyInfo',
    canActivate:[AuthGuard],
    component: CompanyinfoComponent
  },
  {
    path:'myLead',
    canActivate:[AuthGuard],
    component: MyLeadsComponent
  },
  {
    path:'myViewLead',
    canActivate:[AuthGuard],
    component:MyviewLeadsComponent
  },
  {
    path:'myCompany',
    component:MyCompanyComponent
  },
  {
    path:'myCompany',
    component:PostPropertyComponent
  },

  {
    path:'companyProfile',
    canActivate:[AuthGuard],
    component: CompanyProfileComponent
  },
  {
    path: 'loan',
    canActivate:[AuthGuard],
    component:LoanComponent
  },

  {
    path:'forgotpassword',
    canActivate:[AuthGuard],
    component:ForgotpasswordComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
