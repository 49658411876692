<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h2 class="mb-0">Dashboard</h2>
    </div>

    <!-- Content Row -->
    <div class="row pb-top-layout">
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-sales">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Sale Properties</h4>
                            <h5>712</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-rent">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Rent Properties</h4>
                            <h5>436</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-projcts">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Projects</h4>
                            <h5>1,872</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-leads">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Total Leads</h4>
                            <h5>498</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-commercial">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Commercial Properties</h4>
                            <h5>712</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
            <div class="h-100 py-2 pb-loans">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">Loans Passed</h4>
                            <h5>24</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 mb-4">
            <div class="h-100 py-2 pb-current">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <h4 class="mb-1">My Current Plan</h4>
                            <h5>Renewal Date : Apr 04, 2025  (in 360 days) </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Content Row -->
    <div class="row">
        <div class="col-xl-8 col-lg-7">
            <div class="all-leads">
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h3>All Leads</h3>
                            <div class="right-search">
                              <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                    <label for="Sort by:">Sort by:</label>
                                    <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                        <option>Monthly</option>
                                        <option>Weekly</option>
                                        <option>Daily</option>
                                    </select>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover">
                            <thead class="">
                                <tr>
                                <th scope="col"><input type="checkbox"></th>
                                <th scope="col">PropertyId</th>
                                <th scope="col">property Title</th>
                                <th scope="col">Vendor Name</th>
                                <th scope="col">Vendor Mobile</th>
                                <th scope="col">Vendor Email</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let lead of getMylead ">
                                <td></td>
                                <td>{{lead.listingId}}</td>
                                <td>{{lead.propertyTitle}}</td>
                                <td>{{lead.customerName}}</td>
                                <td>{{lead.cmobile}}</td>
                                <td>{{lead.cemail}}</td>

                            </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-5">
            <div class="customer-reviews">
                <div class="card shadow mb-4">
                    <div class="card-body">
                        <h3>Customer Reviews</h3>
                            <div class="review-card d-flex align-items-start">
                                <img src="assets/img/photo1.png" alt="Hina Kumar">
                                <div class="ml-3">
                                    <h3 class="name">Hina Kumar</h3>
                                    <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                    </div>
                                    <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                                </div>
                            </div>
                            <div class="review-card d-flex align-items-start">
                                <img src="assets/img/photo2.png" alt="Kishan Reddy">
                                <div class="ml-3">
                                    <h3 class="name">Kishan Reddy</h3>
                                    <div class="stars">
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-line"></i>
                                    </div>
                                    <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                                </div>
                            </div>
                            <div class="review-card d-flex align-items-start">
                                <img src="assets/img/photo3.png" alt="Rohan Reddy">
                                <div class="ml-3">
                                    <h3 class="name">Rohan Reddy</h3>
                                    <div class="stars">
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-fill"></i>
                                        <i class="ri-star-line"></i>
                                    </div>
                                    <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

</div>